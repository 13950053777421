import React, { FC } from 'react'
import Image from 'next/image'
import { InstagramOutlined, YoutubeFilled, LinkedinFilled } from '@ant-design/icons'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'

import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { Col, Row } from 'src/@legacy/@core/components/share/ANTD/Grid'
import {
  LINK_URL_BLOG_CATEGORY,
  LINK_URL_FACEBOOK,
  LINK_URL_INSTAGRAM,
  LINK_URL_LINKIN,
  LINK_URL_YOUTUBE
} from '../@core/utilities/LinkURL'
import {
  RESOURCE_IMAGE_FREEC_LOGO_WHITE,
  RESOURCE_IMAGE_LOGO_GOV,
  RESOURCE_IMAGE_FACEBOOK,
  RESOURCE_IMAGE_BO_CONG_THUONG
} from '../@core/utilities/Constant'
import { LINK_URL_HR_GLOSSARY, LINK_URL_LP_HEADHUNT_SERVICE } from 'src/@legacy/utilities/LinkURL'
import { useAmp } from 'next/dist/shared/lib/amp'
import env from 'src/@legacy/utilities/env'
import { useTranslateLink } from 'src/@legacy/@core/hooks/UseTranslateLink'
import { useCurrentUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'

const FooterAfterLogin: FC = () => {
  const { translate } = useTranslation()
  const translateLink = useTranslateLink()
  const [currentUser] = useCurrentUser()
  const isAmp = useAmp()

  return (
    <div>
      <style jsx global>{`
        .footer-view {
          background: #001431;
          padding-top: 39px;
          padding-bottom: 32px;
        }
        .footer-title {
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          line-height: 16px;
          color: #47556a;
          margin-bottom: 20px;
        }
        .footer-sub-title {
          font-size: 14px;
          line-height: 16px;
          color: #d0d0d0;
          display: block;
          margin-bottom: 16px;
        }

        .footer-app-icon {
          margin-top: 32px;
          width: 135px;
          height: 48px;
        }
        .icon-social {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background-color: rgba(255, 255, 255, 0.1);
          margin-right: 8px;
          color: #fff;
        }

        .footer-freec {
          background: #fff;
          text-align: center;
          padding: 40px 0;
        }
        .info-contact {
          margin-top: 24px;
        }

        .footer-contact__info,
        .footer-contact__info a {
          font-size: 12px;
          line-height: 18px;
          color: #bfbfbf;
        }

        .footer__copy-right-title {
          font-size: 14px;
          line-height: 16px;
          color: #fff;
          margin-bottom: 12px;
          font-weight: bold;
        }
        .footer__social-icon {
          flex-grow: 0;
          flex-shrink: 0;
          height: 14px;
        }
        .footer__gov-tablet {
          display: none;
        }
        @media only screen and (min-width: 576px) {
          .footer-view {
            padding-top: 64px;
            padding-bottom: 64px;
          }

          .footer__gov-mobile {
            display: none;
          }

          .footer__gov-tablet {
            display: block;
          }

          .footer__copy-right {
            display: flex;
            align-items: flex-start;
          }

          .footer__freec-logo-wrapper {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 24px;
            padding-top: 5px;
          }

          .footer__copy-right-content {
            padding-right: 44px;
          }

          .footer__first-col {
            margin-bottom: 24px;
          }
        }
        @media only screen and (max-width: 576px) {
          .footer__first-col {
            order: 1;
          }

          .mb-under-sm-24-px {
            margin-bottom: 24px;
          }

          .mb-under-sm-75-px {
            margin-bottom: 75px;
          }

          .footer-sub-title {
            width: 49%;
            display: inline-block;
          }

          .footer__freec-logo-wrapper {
            margin-bottom: 19px;
          }
        }
        @media screen and (max-width: 768px) {
          .footer-freec {
            padding: 32px 0;
            text-align: left;
          }
        }
      `}</style>

      <div className="footer-view">
        <WrapperLayout className="wrapper-container">
          <Row>
            <Col className="footer__first-col" xs={24} lg={12}>
              <div className="footer__copy-right">
                <div className="footer__freec-logo-wrapper">
                  <Image className="footer__freec-logo" src={RESOURCE_IMAGE_FREEC_LOGO_WHITE} alt="freec-platform" width={59} height={40} />
                </div>

                <div className="footer__copy-right-content">
                  <div className="footer__copy-right-title">{translate('footer:new_copyright')}</div>
                  <div className="footer-contact__info">
                    {translate('footer:info_phone')}
                    <br />
                    {translate('footer:info_address')}
                    <br />
                    {translate('footer:info_certificate_name')}
                    <br />
                    {translate('footer:info_certificate_number')} - {translate('footer:info_certificate_date_created')} -{' '}
                    {translate('footer:info_certificate_place')}
                  </div>
                </div>
              </div>
              <div className="footer__gov-mobile">
                <a target="_blank" rel="noopener noreferrer" href={'http://online.gov.vn/Home/WebDetails/65654'}>
                  <div className="footer-app-icon">
                    {isAmp === true ? (
                      <amp-img width={135} height={48} src={RESOURCE_IMAGE_LOGO_GOV} alt="gov icon" layout="responsive" />
                    ) : (
                      <Image src={RESOURCE_IMAGE_BO_CONG_THUONG} alt="gov icon" width={133} height={50} />
                    )}
                  </div>
                </a>
              </div>
            </Col>
            <Col className="mb-under-sm-24-px" xs={24} sm={7} lg={3}>
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:about_freecracy')}</div>
                <div>
                  <a
                    className="footer-sub-title"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={translateLink(`${env.PUBLIC_FOOTER_JOB_OPPORTUNITY}`)}>
                    {translate('footer:about_us')}
                  </a>
                  <a className="footer-sub-title" target="_blank" rel="noopener noreferrer" href={`${env.PUBLIC_FOOTER_TERMS}`}>
                    {translate('footer:terms')}
                  </a>
                  <a className="footer-sub-title" target="_blank" rel="noopener noreferrer" href={`${env.PUBLIC_FOOTER_PRIVACY}`}>
                    {translate('footer:policy')}
                  </a>
                  <a
                    className="footer-sub-title"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={translateLink(`${env.PUBLIC_FOOTER_JOB_OPPORTUNITY}`)}>
                    {translate('footer:careers')}
                  </a>
                </div>
              </div>
            </Col>
            <Col className="mb-under-sm-24-px" xs={24} sm={9} lg={5}>
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:for_employers')}</div>
                <div>
                  <a
                    className="footer-sub-title"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={translateLink(`${env.PUBLIC_EMPLOYER_APP_DOMAIN}${LINK_URL_LP_HEADHUNT_SERVICE}`)}>
                    {translate('footer:headhunting_services')}
                  </a>
                  <a className="footer-sub-title" target="_blank" rel="noopener noreferrer" href={LINK_URL_BLOG_CATEGORY}>
                    {translate('footer:guideline_for_recruiters')}
                  </a>
                  <a
                    className="footer-sub-title"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://blog.freec.asia/60-mau-tin-dang-tuyen-dung-cac-nganh-nghe/`}>
                    {translate('footer:job_description_template')}
                  </a>
                  <a className="footer-sub-title" target="_blank" rel="noopener noreferrer" href={LINK_URL_HR_GLOSSARY}>
                    {translate('footer:hr_glossary')}
                  </a>
                  <a className="footer-sub-title" target="_blank" rel="noopener noreferrer" href={`${env.PUBLIC_FOOTER_HELP_CENTER}`}>
                    {translate('footer:help_center')}
                  </a>
                </div>
              </div>
            </Col>
            <Col className="mb-under-sm-75-px" xs={24} sm={8} lg={4}>
              <div className="d-flex flex-column">
                <div className="footer-title">{translate('footer:connect_to_freec')}</div>
                <div className="d-flex">
                  <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_FACEBOOK}>
                    <div className="footer__social-icon">
                      <Image src={RESOURCE_IMAGE_FACEBOOK} alt="freec-platform" width={14} height={14} />
                    </div>
                  </a>
                  <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_LINKIN}>
                    <LinkedinFilled />
                  </a>
                  <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_INSTAGRAM}>
                    <InstagramOutlined />
                  </a>
                  <a target="_blank" className="icon-social" rel="noopener noreferrer" href={LINK_URL_YOUTUBE}>
                    <YoutubeFilled />
                  </a>
                </div>
              </div>
              <div className="footer__gov-tablet">
                <a target="_blank" rel="noopener noreferrer" href={'http://online.gov.vn/Home/WebDetails/65654'}>
                  <div className="footer-app-icon">
                    {isAmp === true ? (
                      <amp-img width={135} height={48} src={RESOURCE_IMAGE_LOGO_GOV} alt="gov icon" layout="responsive" />
                    ) : (
                      <Image src={RESOURCE_IMAGE_BO_CONG_THUONG} alt="gov icon" width={133} height={50} />
                    )}
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </WrapperLayout>
      </div>
      {/* <div className="footer-freec">
        <WrapperLayout className="wrapper-container">
          {isAmp === true ? (
            <div style={{ width: 72, height: 48, margin: 'auto' }}>
              <amp-img width={72} height={48} src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" layout="responsive" />
            </div>
          ) : (
            <Image src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" width={72} height={48} />
          )}

          <div className="info-contact">
            <strong>{translate('footer:new_copyright')}</strong>
            <br />
            {translate('footer:info_phone')}
            <br />
            {translate('footer:info_address')}
            <br />
            {translate('footer:info_certificate_name')}&nbsp; {translate('footer:info_certificate_number')}&nbsp;{' '}
            {translate('footer:info_certificate_date_created')}&nbsp; {translate('footer:info_certificate_place')}
          </div>
        </WrapperLayout>
      </div> */}
    </div>
  )
}

export default FooterAfterLogin
